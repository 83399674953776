export const LOGIN_ROUTE_SET = '/'
// export const LOGIN_ROUTE = '/login'
export const LOGIN_ROUTE = '/'
export const FORGOT_ROUTE = '/forgotpassword'
export const CHAT_ROUTE = '/dialog'
export const MAIN_ROUTE = '/home'
export const DOC_ROUTE = '/docs'
export const NOTIFICSTION_ROUTE = '/notification'
export const SETTINGS_ROUTE = '/settings'
export const SEO_ROUTE = '/seo'
export const SEO_MAIN_ROUTE = '/seo/main'
export const SEO_Position_ROUTE = '/seo/position'
export const SEO_INDEX_ROUTE = '/seo/index'
export const SEO_REPORT_ROUTE = '/seo/report'
export const SEO_SERVICES_ROUTE = '/seo/services'
export const SEO_INSPECTION_ROUTE = '/seo/inspection'
export const SEO_NEWS_ROUTE = '/seo/news'
export const SEO_DETAILS_ROUTE = '/seo/details'
export const SEO_CONTACTS_ROUTE = '/seo/contacts'
export const CHAT_ROUTE_SUPPORT = '/dialog_support'

// export const SEO_ROUTE = '/seo'
// export const SEO_ROUTE = '/seo'
// export const SEO_ROUTE = '/seo'