import React from 'react'

const Loader = () => {
    return (
        <div className="loader_wrapper">
        <div className="square">
                <div className="circle">
                </div>
                <div className="circle">
                </div>
        </div>
        </div>
    
    )
}
export default Loader;
