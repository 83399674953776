import React from 'react'
import Footer from './Footer'
import Header from './Header'
import MainBlock from './MainBlock'

export default function MainPage() {
    return (
        <div>
            
      <header>
        <Header />
       
      </header>

      <MainBlock />

    <Footer/>
        </div>
    )
}
